a {
    cursor: pointer;
}

.react-select div,
.react-select input{
    color: white !important;
}

.white .react-select div,
.white .react-select input{
    color: black !important;
}
.DynamicTableContainer .react-select__multi-value{
    background-color: #0a334c;
}
.x-center {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
}

.y-center {
    display: inline-block;
    margin-top: 50%;
    transform: translateY(-50%);
}

.form-label {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0 !important;
}

@media (max-width: 767px) {
    .form-label {
        flex: 1;
        display: flex;
    }
}

.form-control::placeholder {
    opacity: 0.5 !important;
}

.child-h-100>* {
    height: 100% !important;
}

.react-select {
    text-align: left !important;
}

.react-select.is-focused {
    z-index: 20000;
}

.react-select>div,
.react-select:hover>div {
    color: var(--bs-body-color);
    background-color: var(--bs-input-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 3px;
}

.react-select:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-input-bg);
    border-color: var(--bs-border-color);
    outline: 0;
    box-shadow: none;
}

.react-select__single-value,
.react-select__input {
    color: var(--bs-body-color);
}

.react-select__control {
    box-shadow: none !important;
}

.react-select__indicator-separator {
    background-color: var(--bs-body-color) !important;
    color: var(--bs-body-color) !important;
}

.react-select__indicator {
    color: var(--bs-body-color) !important;
}

.react-select__option--is-focused {
    background-color: var(--bs-primary) !important;
}

.react-select__option span {
    white-space: nowrap;
}

/*
.DynamicTableContainer{
    min-width: 400px;
}
.DynamicTableCard{
    min-width:460px!important;
}*/