// 
// _horizontal.scss
// 


body[data-layout="horizontal"] {

    #page-topbar{
        left: 0;
    }

    .navbar-brand-box {
        display: block;
        width: auto;
        border: 0;
        background-color: transparent;
        box-shadow: none;
        position: relative;
        @media  (min-width: 992px){
            padding-left: 0;
            margin-right: 10px;
        }
    }

    .page-content {
        margin-top: $header-height;
        padding: $grid-gutter-width 0 0 0;
        padding: $grid-gutter-width 0 $footer-height 0;
        @media (max-width: 991.98px) { 
            padding-top: $grid-gutter-width;
        }
    }

    .navbar-header{
        @media (min-width: 992px){
            padding-left: calc(#{$grid-gutter-width} / 2);
            padding-right: calc(#{$grid-gutter-width} / 2);
        }
    }

    &[data-sidebar-size=sm]{
        .navbar-brand-box{
            width: auto !important;
        }
    }
}

.topnav {
    padding: 0 calc(#{$grid-gutter-width} / 2);
    z-index: 100;
    @media (max-width: 991.98px) {
        position: fixed;
        left: 0;
        right: 0;
        top: $header-height;
        z-index: 100;
        background: $topnav-bg;
        box-shadow: $box-shadow;
       
    }
    
    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {

        .nav-item {
            margin: 0 0.85rem;
        }
        
        .nav-link {
            font-size: 14.4px;
            position: relative;
            padding: 0;
            color: var(--#{$prefix}menu-item-color);
            font-weight: $font-weight-normal;
            line-height: $header-height;
            i {
                font-size: 16px;
                margin-right: 6px;
                vertical-align: middle;
            }
            svg {
                height: 16px;
                width: 16px;
                color: var(--#{$prefix}menu-item-color);
                margin-right: 5px;
                margin-top: -1px;
            }
            &:focus, &:hover{
                color: var(--#{$prefix}menu-item-active-color);
                background-color: transparent;
                svg {
                    color:var(--#{$prefix}menu-item-active-color);
                }
            }
        }
        
        .dropdown-item{
            color: var(--#{$prefix}menu-item-color);
            background: transparent;
            &.active, &:hover{
                color: var(--#{$prefix}menu-item-active-color);
            }
        }
        
        .nav-item{
            .nav-link.active{
                color: var(--#{$prefix}menu-item-active-color);
                svg {
                    color: var(--#{$prefix}menu-item-active-color);
                }
            }

            @media (min-width: 992px) {
                &.active{
                    >.nav-link{
                        &::before{
                            content: "";
                            height: 2px;
                            width: 100%;
                            background-color: $primary;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }
            }
        }

        .dropdown{
            &.active{
              >a {
                    color: $sidebar-menu-item-active-color;
                    background-color: transparent;
                    svg {
                        color: $menu-item-active-color;
                    }
                }
            }
            .dropdown{
                &.active{
                    >a {
                          color: $sidebar-menu-item-active-color;
                      }
                  }
            }
        }
    }

    .menu-title{
        padding: 12px 24px !important;
        @media (max-width: 991.98px) {
            padding: 12px 16px !important;
        }
    }
}

@include media-breakpoint-up(xl) {

    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 85%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .dropdown-item {
            padding: .5rem 1.5rem;
            min-width: 180px;
        }

        .dropdown {
            &.mega-dropdown{
                // position: static;
                .mega-dropdown-menu{
                    left: 0px;
                    right: auto;
                }
            }
            .dropdown-menu {
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0 !important;
                        left: 100%;
                        display: none;
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 0;
        top: 50%;
        margin-left: 5px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
    }
}


@include media-breakpoint-down(xl) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {

    .navbar-brand-box{
        .logo-dark {
            display: $display-block;
            span.logo-sm{
                display: $display-block;
            }
        }
    
        .logo-light {
            display: $display-none;
        }
    }
    
    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
                line-height: inherit;
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 20px;
                &.dropdown-mega-menu-xl{
                    width: auto;
    
                    .row{
                        margin: 0px;
                    }
                }
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $primary;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}


// Dark Topbar 

body[data-topbar="dark"] {
    .topnav {
        .navbar-nav {
            
            .nav-link {
                color: $menu-dark-item-color;
                &:focus, &:hover{
                    color: $menu-dark-item-hover-color;
                    svg {
                        color: $menu-dark-item-hover-color;
                    }
                }
                @media (max-width: 991.98px) {
                    color:  var(--#{$prefix}menu-item-color);
                    &:focus, &:hover{
                        color: $menu-item-active-color ;
                        svg {
                            color: $menu-item-active-color;
                        }
                    }
                  
                }
            }
            
            .nav-item{
                .nav-link.active{
                    color: $menu-dark-item-active-color;
                    svg {
                        color: $menu-dark-item-active-color;
                    }
                    @media (max-width: 991.98px) {
                        color: $menu-item-active-color;
                        svg {
                            color: $menu-item-active-color;
                        }
                    }
                }
            }

            .dropdown{
                &.active{
                  >a {
                        color: $menu-dark-item-active-color;
                        background-color: transparent;
                    }
                }
                .dropdown{
                    &.active{
                        >a {
                              color: $menu-item-active-color;
                          }
                      }
                }

                @media (max-width: 991.98px) {
                    &.active{
                        >a {
                              color: $menu-item-active-color;
                              background-color: transparent;
                          }
                      }
                      .dropdown{
                          &.active{
                              >a {
                                    color: $menu-item-active-color;
                                }
                            }
                      }
                  
                }
            }
        }
    }
}


body[data-bs-theme="dark"][data-topbar="light"]{
    .topnav{
        .navbar{
            .nav-link{
                color: black !important;
            }
        }
    }
}


// body[data-layout-mode="dark"] {
//     .topnav {


//         .navbar-nav{
//             .nav-link{
//                 color: $menu-dark-item-color;

//                 svg {
//                     color: $menu-dark-item-color;
//                 }

//                 &:focus, &:hover{
//                     color: $menu-dark-item-active-color;
//                     background-color: transparent;
//                     svg {
//                         color: $menu-dark-item-active-color;
//                     }
//                 }
//             }

//             .dropdown-item{
//                 color: $menu-dark-item-color;
//                 background-color: transparent;

//                 &.active, &:hover{
//                     color: $menu-dark-item-active-color;
//                 }
//             }

//             .nav-item{
//                 .nav-link.active{
//                     color: $menu-dark-item-active-color;
//                     svg {
//                         color: $menu-dark-item-active-color;
//                     }
//                 }
//             }

//             .dropdown{
//                 &.active{
//                   >a {
//                         color:  $menu-dark-item-active-color;
//                         background-color: transparent;
//                         svg {
//                             color: $menu-dark-item-active-color;
//                         }
//                     }
//                 }
//                 .dropdown{
//                     &.active{
//                         >a {
//                               color:  $menu-dark-item-active-color;
//                           }
//                       }
//                 }
//             }
//         }

//         @media (max-width: 991.98px) {
//             background: lighten($gray-dark-200,2.5%);
           
//         }

//         .menu-title{
//             color: rgba($menu-dark-item-color,0.6);
//         }
//     }
// }

