// 
// _layouts.scss
// 
@media (max-width: 576px) {
    .navbar-brand-box {
        width: 100%;
        height: 100%;
        position: absolute;
    }
        
    #page-topbar {
        display: block !important;
    }
}

.main-content {
    margin-top: 70px;
    max-height: 100vh;
}

body[data-layout-size="boxed"] {
    background-color: var(--#{$prefix}boxed-body-bg);

    #layout-wrapper {
        background-color: var(--#{$prefix}body-bg);
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: var(--#{$prefix}box-shadow);
    }

    #page-topbar {
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &[data-sidebar-size="sm"] {
        #page-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }

        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }

        .main-content {
            @media (min-width: 769px) {
                min-height: 1850px;
            }
        }
    }

    &[data-sidebar-size="md"] {
        #page-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});
        }

        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});
        }
    }
}

.margin-null {
    .vertical-menu {
        //display: none !important;
    }

    .main-content {
        margin-left: 0;
        margin-top: 0;
    }
}

body[data-layout="vertical"] {

    #page-topbar {
        &.ishorizontal-topbar {
            display: none;
        }

    }

    .isvertical-topbar {
        display: block;
    }

    .vertical-menu {
        display: block;

        @media (max-width: 991.98px) {
            //display: none !important;
        }
    }

}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {

    #page-topbar,
    #layout-wrapper,
    .footer {
        max-width: 100%;
    }

    .container-fluid,
    .navbar-header {
        max-width: $boxed-layout-width;
    }
}

// Scrollable layout
body[data-layout-scrollable="true"] {
    @media (min-width: 992px) {

        #page-topbar,
        .vertical-menu {
            position: absolute;
        }

        .navbar-brand-box {
            position: absolute;
        }
    }

    &[data-layout="horizontal"] {
        @media (min-width: 992px) {
            #page-topbar {
                position: absolute;
            }

            .navbar-brand-box {
                position: relative;
            }
        }
    }
}


// vertical


// body[data-bs-theme="dark"] {
//     &[data-layout-size="boxed"] {
//         background-color: #082a3e;
//         #layout-wrapper {
//             background-color: #051823;
//         }
//     }
// }

// Layout Setting Button
.layout-setting-btn {
    position: fixed;
    top: 47.5%;
    right: 0;
    writing-mode: vertical-rl;
    font-weight: 600;
    background-color: $primary;
    color: $white !important;
    line-height: 32px;
    padding: 15px 3px;
    font-size: 16px;
    border-radius: 6px 0 0 6px;
    transform: translateY(-50%);
    z-index: 1999;
}